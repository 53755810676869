import { Types } from '@fiverr-private/theme';
import { formsTokenColors } from '../shared/formsTokenColors';
import { CheckboxProps } from './types';

interface GetValidationColor {
    color: Types.SelectorTypographyColorsType;
    borderColor?: Types.BorderColor;
}

export const getValidationColor = ({
    disabled,
    isError,
    isSuccess,
}: {
    disabled: CheckboxProps['disabled'];
    isError: CheckboxProps['isError'];
    isSuccess: CheckboxProps['isSuccess'];
}): GetValidationColor => {
    if (disabled) {
        return { color: formsTokenColors.disabledColor, borderColor: formsTokenColors.borderColor };
    } else if (isError) {
        return { color: 'validationError', borderColor: 'validationError' };
    } else if (isSuccess) {
        return { color: 'validationSuccess', borderColor: 'validationSuccess' };
    } else {
        return { color: formsTokenColors.color, borderColor: formsTokenColors.borderColor };
    }
};
